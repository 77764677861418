import React from "react";
import "./contact.scss";

interface Props {
  contacts: IContact;
}

export const Contact: React.FC<Props> = ({ contacts }) => {
  return (
    <div id="contacts">
      <h1>Elérhetőség</h1>
      <h3>
        Email:{" "}
        <a href="mailto:toroktruck@gmail.com?subject=Ajánlat kérés">
          {contacts.email}
        </a>
      </h3>
      <h3>
        Telefon: <a href="tel:+3670-389-8665">{contacts.telephone}</a>
      </h3>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2747.6619663235865!2d19.972839515987694!3d46.47520327912595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474388a265485da1%3A0x8d6ef9f662c72a48!2sKistelek%2C%20Liget%20u.%201%2C%206760!5e0!3m2!1sen!2shu!4v1599939975748!5m2!1sen!2shu"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        aria-hidden="false"
        tabIndex={0}
        title="Google maps"
      />
    </div>
  );
};
