import React, { useState } from "react";
import { Description } from "./Description";

interface Props {
  prices: IProduct;
}

export const Body: React.FC<Props> = ({ prices }) => {
  const [selectedProduct, selectProduct] = useState("");
  return (
    <div className="price-body">
      {Object.keys(prices)
        .filter((n) => n !== "name")
        .map((key) => (
          <div className="row" key={key} onClick={() => selectProduct(key)}>
            <div className="item">{prices[key].name}</div>
            <div className="item">{prices[key].price}</div>
          </div>
        ))}
      <Description
        selectedProduct={selectedProduct ? prices[selectedProduct] : null}
      />
    </div>
  );
};
