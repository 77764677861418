import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from "./Header";
import { getData, isLoaded } from "./utils";
import { Prices } from "./Prices/index";
import { Contact } from "./Contact";

function App() {
  const [state, setState] = useState<IState | null>();
  useEffect(() => {
    getData().then((data) => {
      setState(data);
    });
  });
  return (
    <div id="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/about">
            <h1>Rólunk</h1>
          </Route>
          <Route path="/contact">
            {isLoaded(state) && <Contact contacts={state.contact} />}
          </Route>
          <Route path="/">
            <h1>Árak</h1>
            {isLoaded(state) && <Prices prices={state.prices} />}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
