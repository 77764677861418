import React from "react";
import { NavLink } from "react-router-dom";

export const Header = () => {
  return (
    <div id="header">
      <img className="logo" src="logo.jpg" alt="logo" />
      <nav>
        <div>
          <NavLink exact to="/" activeClassName="selected">
            Termékeink
          </NavLink>
        </div>
        <div>
          <NavLink to="/about" activeClassName="selected">
            Rólunk
          </NavLink>
        </div>
        <div>
          <NavLink to="/contact" activeClassName="selected">
            Kapcsolat
          </NavLink>
        </div>
      </nav>
    </div>
  );
};
