import React from "react";

interface Props {
  keys: Record<ICategories, string>;
  selectCategory: React.Dispatch<React.SetStateAction<ICategories | null>>;
  selectedCategory: keyof IPrices | null;
}

export const Header: React.FC<Props> = ({
  keys,
  selectedCategory,
  selectCategory,
}) => {
  return (
    <div className="price-header">
      {(Object.keys(keys) as ICategories[]).map((key) => (
        <button
          className={key === selectedCategory ? "selected" : ""}
          onClick={() => selectCategory(key as ICategories)}
          key={key}
        >
          {keys[key]}
        </button>
      ))}
    </div>
  );
};
