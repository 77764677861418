import React, { useState } from "react";
import { Header } from "./Header";
import { Body } from "./Body";

interface Props {
  prices: IPrices;
}

export const Prices: React.FC<Props> = ({ prices }) => {
  const [selectedCategory, selectCategory] = useState<ICategories | null>(null);
  if (!prices) return null;
  const keys = (Object.keys(prices) as ICategories[]).reduce(
    (acc: Partial<Record<ICategories, string>>, key: ICategories) => {
      acc[key] = prices[key].name;
      return acc;
    },
    {}
  ) as Record<ICategories, string>;

  return (
    <div id="prices">
      <Header
        keys={keys}
        selectCategory={selectCategory}
        selectedCategory={selectedCategory}
      />
      {selectedCategory && <Body prices={prices[selectedCategory]} />}
    </div>
  );
};
