import React from "react";
import "./Description.style.scss";

interface Props {
  selectedProduct: IPrice | null;
}

export const Description: React.FC<Props> = ({ selectedProduct }) => {
  if (!selectedProduct) return null;
  return (
    <div>
      <img
        className="img"
        src={selectedProduct.image}
        alt={selectedProduct.image}
      />
      <p>{selectedProduct.description}</p>
    </div>
  );
};
